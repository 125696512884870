"use client";
import Script from "next/script";
import { PropsWithChildren, useEffect, useRef } from "react";
import { PublicConfig } from "@/shared/PublicConfig";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { getDataDogRumScript, getDataDogLogsScript } from "../scripts/datadog";
import { getSegmentScript } from "../scripts/segment";
import { usePathname } from "next/navigation";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";

interface AnalyticsProviderProps extends PropsWithChildren {
    underwriter: PetUnderwriterType;
    serviceName: string;
}

const getUnderwriterConfig = (underwriter: PetUnderwriterType) => {
    const configs = {
        "ptz-ca": PublicConfig.PTZ_CA,
        "ptz-us": PublicConfig.PTZ_US
    };
    return configs[underwriter];
};

const analyticsUtils = new AnalyticsUtils();

export const AnalyticsProvider = ({ underwriter, children, serviceName }: AnalyticsProviderProps) => {
    const underwriterConfig = getUnderwriterConfig(underwriter);
    const lastPathname = useRef<string>("");
    const pathname = usePathname();

    useEffect(() => {
        if (lastPathname.current !== pathname) {
            lastPathname.current = pathname;
            analyticsUtils.initSegmentPageCall(10);
        }
    }, [pathname]);

    return (
        <>
            <Script
                id="datadog-rum"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: getDataDogRumScript(
                        PublicConfig.DATADOG_CLIENT_TOKEN,
                        PublicConfig.DATADOG_APPLICATION_ID,
                        serviceName,
                        PublicConfig.ENVIRONMENT,
                        PublicConfig.DATADOG_APPLICATION_VERSION ?? "unknown",
                        PublicConfig.DATADOG_SESSION_SAMPLE_RATE,
                        PublicConfig.DATADOG_SESSION_RECORD_RATE
                    )
                }}
            />
            <Script
                id="datadog-logs"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: getDataDogLogsScript(PublicConfig.DATADOG_CLIENT_TOKEN, serviceName, PublicConfig.ENVIRONMENT, PublicConfig.DATADOG_APPLICATION_VERSION ?? "unknown")
                }}
            />
            <Script
                id="segment"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: getSegmentScript(underwriterConfig.SEGMENT_WEB_WRITE_KEY, underwriterConfig.SEGMENT_CDN_URL)
                }}
            />
            {children}
        </>
    );
};
